














import KTextAreaInput from '@/@core/components/input/KTextAreaInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import useModelValue from '@/@core/helpers/modelValue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { KTextAreaInput, KTextInput },
  name: 'NoteForm',
  setup(props, { emit, attrs }) {
    const modelValue = useModelValue({ emit, attrs });

    return {
      modelValue
    };
  }
});
